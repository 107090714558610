(() => {
  if (document.querySelector('.course-slider')) {
    let slider = tns({
      container: '.course-slider',
      items: 1,
      loop: false,
      rewind: true,
      slideBy: 'page',
      gutter: 1,
      speed: 600,
      autoplay: true,
      autoplayButtonOutput: false,
      mouseDrag: true,
      controlsContainer: '#course-slider-controls',
      nav: false,
    });
  }

  if (document.querySelector('.gallery-slider')) {
    let slider = tns({
      container: '.gallery-slider',
      items: 1,
      slideBy: 'page',
      loop: false,
      rewind: true,
      gutter: 1,
      speed: 600,
      autoplay: true,
      autoplayButtonOutput: false,
      mouseDrag: true,
      controlsContainer: '#gallery-slider-controls',
      nav: false,
    });
  }

  if (document.querySelector('.review-slider')) {
    let slider = tns({
      container: '.review-slider',
      loop: false,
      rewind: true,
      items: 2,
      slideBy: 1,
      gutter: 20,
      speed: 600,
      autoplay: true,
      autoplayButtonOutput: false,
      mouseDrag: true,
      controlsContainer: '#review-slider-controls',
      nav: false,
      responsive: {
        768: {
          items: 2
        },
        320: {
          items: 1
        }
      }
    });
  }

  if (document.querySelector('.base-slider')) {
    let slider = tns({
      container: '.base-slider',
      items: 3,
      slideBy: 1,
      loop: false,
      rewind: true,
      gutter: 0,
      speed: 600,
      autoWidth: true,
      autoplay: true,
      autoplayButtonOutput: false,
      mouseDrag: true,
      controlsContainer: '#base-slider-controls',
      nav: false,
      responsive: {
        1800: {
          gutter: 28.5,
          edgePadding: 6,
        },
        1400: {
          gutter: 22,
          edgePadding: 6,
        },
        1024: {
          gutter: 20,
          edgePadding: 6,
        },
        320: {
          gutter: 10,
          edgePadding: 5,
        }
      }
    });
  }

  if (document.querySelector('.base-inner-slider')) {
    document.querySelectorAll('.base-inner-slider').forEach((element) => {
      let slider = tns({
        container: element,
        items: 4,
        loop: false,
        rewind: true,
        slideBy: 1,
        gutter: 32,
        speed: 600,
        autoplay: true,
        autoplayButtonOutput: false,
        mouseDrag: true,
        controls: Boolean(element.dataset.slider),
        controlsContainer: `#${element.dataset.slider}`,
        nav: false,
        responsive: {
          1440: {
            gutter: 32,
          },
          1024: {
            gutter: 24,
            items: 4
          },
          600: {
            items: 3
          },
          480: {
            items: 2
          },
          360: {
            autoWidth: true,
            items: 1
          },
        }
      });
    });
  }
})();
