(() => {
  if (document.querySelectorAll('.js-video-main').length) {
    document.querySelectorAll('.js-video-main').forEach((c) => {
      c.querySelector('.js-video-play').addEventListener('click', function () {
        let mediaVideo = c.querySelector('.js-video');
        if (mediaVideo.paused) {
          mediaVideo.play();
          mediaVideo.setAttribute("controls", "controls");
          mediaVideo.closest(".js-video-main").classList.add('active');
        } else {
          mediaVideo.pause();
          mediaVideo.removeAttribute("controls");
          mediaVideo.closest(".js-video-main").classList.remove('active');
        }

        mediaVideo.addEventListener('ended', () => {
          mediaVideo.removeAttribute("controls");
          mediaVideo.closest(".js-video-main").classList.remove('active');
        }, false);
      });
    });
  }
})();
