// Переключение табов

(function () {
  let tabWork = function (tabParent) {
    let
      tabArray = window.utils.getArrayFrom(tabParent.querySelector('.js-tab-item').children),
      tabTargetArray = window.utils.getArrayFrom(tabParent.querySelector('.js-tab-target').children),
      tabActive = tabParent.querySelector('.js-tab-item .active');

    function toggleTabEvent(current, index) {
      let activeArray = Array.from(tabParent.querySelectorAll('.active'));

      activeArray.forEach(function (activeCurrent) {
        activeCurrent.classList.remove('active');
      });

      current.classList.add('active');
      tabTargetArray[index].classList.add('active');

      tabActive = current;
    }

    tabArray.forEach(function (current, index) {
      if (!current.classList.contains('disabled')) {
        current.addEventListener('click', function () {
          toggleTabEvent(current, index);
        });

        current.addEventListener('keydown', function (evt) {
          if (evt.key === window.utils.keyEnter || evt.key === window.utils.keySpace) {
            toggleTabEvent(current, index);
          }
        });
      }
    });
  };

  window.tab = {
    tabWork: tabWork
  }

})();

(() => {
  if (document.querySelectorAll('.js-tab-parent').length) {
    document.querySelectorAll('.js-tab-parent').forEach(function (current) {
      window.tab.tabWork(current);
    });
  }
})();
